/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~ol/ol.css';
@import 'app/scss/ol-custom.scss';

@import '~tailwindcss/base.css';
@import '~tailwindcss/components.css';
@import '~tailwindcss/utilities.css';

@import url('https://fonts.googleapis.com/css2?family=Abel&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
// @import 'app/scss/colors';
// @import 'app/scss/breakpoints';

/** To override the dark theme, go to NightThemeComponent's scss file **/
/* Here we override taiga ui theme colors */
:root {
    --tui-heading-font: "Roboto", "Abel", "Manrope", -apple-system, "BlinkMacSystemFont", system-ui, "Segoe UI", "Helvetica Neue", sans-serif;
    --tui-text-font: "Roboto", "Abel", "Manrope", -apple-system, "BlinkMacSystemFont", system-ui, "Segoe UI", "Helvetica Neue", sans-serif;
    // Base colors
    // --tui-background-base: #50A2A7;
    // --tui-background-base-alt: #FAFAFA;
    --tui-background-base-opaque: #ffffffb1;
    --tui-background-base-alt-opaque: #f6f6f6b1;
    // --tui-border-hover: #7663d3;
    --tui-background-accent-1: #009DE0;
    --tui-background-accent-1-hover: #0081B8;
    --tui-background-accent-1-pressed: #00648F;
    // --tui-background-neutral-1: #D17B0F;

    // font-family: var(--tui-text-font)
}