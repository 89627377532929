// Openlayers map control overrides
.ol-mouse-position {
    position: initial;
    width: 10rem;
}

.ol-scale-line {
    position: initial;
    background-color: transparent;

    &-inner {
        color: var(--tui-text-primary);
        background-color: var(--tui-border-normal);
    }
}

.ol-control button {
    font-size: 1.2rem;
    border-radius: var(--tui-radius-s);
    color: var(--tui-text-primary);
    background-color: var(--tui-background-base);

    &:hover {
        color: var(--tui-text-primary);
        background-color: var(--tui-background-base);
        background-color: var(--tui-background-neutral-1);
    }
}

.ol-zoom {
    top: auto;
    bottom: 4em;
}

.ol-overviewmap {
    bottom: 9em;
}

.themeable-layer {
    filter: grayscale(0) invert(0);
}

.themeable-layer.dark-theme {
    filter: grayscale(80%) invert(100%);
}

// @media screen and (prefers-color-scheme: dark) {
//     .themeable-layer {
//         filter: grayscale(80%) invert(100%);
//     }

//     .themeable-layer.light-theme {
//         filter: grayscale(0) invert(0);
//     }
// }
